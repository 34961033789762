import React from 'react';

const HalfStar = ({ colour }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2)">
      <path d="M8.09583 0.913827C8.45667 0.150051 9.54333 0.150053 9.90417 0.913829L11.5334 4.36241C11.683 4.67894 11.9872 4.89423 12.3354 4.93L16.1996 5.32694C17.0726 5.41663 17.4149 6.5072 16.7496 7.07969L13.9419 9.49588C13.663 9.73592 13.5384 10.1098 13.6176 10.4691L14.4054 14.0424C14.59 14.8799 13.7037 15.5461 12.9505 15.1358L9.47834 13.2446C9.18013 13.0821 8.81987 13.0821 8.52166 13.2446L5.04954 15.1358C4.29632 15.5461 3.41 14.8799 3.59465 14.0424L4.38238 10.4691C4.4616 10.1098 4.33704 9.73592 4.0581 9.49588L1.25035 7.07969C0.58509 6.5072 0.927357 5.41663 1.80044 5.32694L5.6646 4.93C6.01285 4.89423 6.31704 4.67894 6.46658 4.3624L8.09583 0.913827Z" fill={colour} />
      <mask id="mask0_1_2" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="16">
        <path d="M8.09583 0.913827C8.45667 0.150051 9.54333 0.150053 9.90417 0.913829L11.5334 4.36241C11.683 4.67894 11.9872 4.89423 12.3354 4.93L16.1996 5.32694C17.0726 5.41663 17.4149 6.5072 16.7496 7.07969L13.9419 9.49588C13.663 9.73592 13.5384 10.1098 13.6176 10.4691L14.4054 14.0424C14.59 14.8799 13.7037 15.5461 12.9505 15.1358L9.47834 13.2446C9.18013 13.0821 8.81987 13.0821 8.52166 13.2446L5.04954 15.1358C4.29632 15.5461 3.41 14.8799 3.59465 14.0424L4.38238 10.4691C4.4616 10.1098 4.33704 9.73592 4.0581 9.49588L1.25035 7.07969C0.58509 6.5072 0.927357 5.41663 1.80044 5.32694L5.6646 4.93C6.01285 4.89423 6.31704 4.67894 6.46658 4.3624L8.09583 0.913827Z" fill={colour} />
      </mask>
      <g mask="url(#mask0_1_2)">
        <rect x="9" width="10" height="17" fill="#D9D9D9" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="18" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HalfStar;
