import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { reactLocalStorage } from 'reactjs-localstorage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { pageId, pageType, pageSlug, tier, cookies, logErrorPath } = this.props;

    const preferedLayout = cookies.get('prefered_directory_layout') || 'table';

    let filters = null;
    if (pageType === 'product') {
      filters = reactLocalStorage.getObject(`${pageSlug}:filter_json`, null);
    }

    const errorData = {
      error,
      errorInfo: error.stack,
      userTier: tier,
      filters,
      layout: preferedLayout
    };

    $.ajax({
      type: 'POST',
      url: logErrorPath,
      data: { error: errorData, id: pageId, type: pageType },
    });
  }

  render() {
    if (this.state.hasError) {
      return <div dangerouslySetInnerHTML={{ __html: this.props.errorPage }} />;
    }

    return this.props.children;
  }
}

export default withCookies(ErrorBoundary);
